import React, {Component} from 'react';
import {Drawer, Button} from 'antd';
import PropTypes from "prop-types";
import MyMenu from "./menu";
import {Link} from "gatsby";

import nerpa_text from "../images/nerpa_text_2.svg";
import {MenuOutlined} from '@ant-design/icons';

class Navbar extends Component {
    state = {
        visible: false
    };

    showDrawer = () => {
        this.setState({
            visible: true,
        });
    };

    onClose = () => {
        this.setState({
            visible: false,
        });
    };

    render() {
        return (
            <nav className="menuBar">
                <Link to="/"><img className="logo" src={nerpa_text} alt="NERPA" width="140px" height="27px"/></Link>
                <div className="leftMenu">
                    <MyMenu isProject={this.props.isProject} mode="horizontal"/>
                </div>
                <div className="barsMenu">
                    <Button type="default" onClick={this.showDrawer}>
                        <MenuOutlined/>
                    </Button>
                </div>
                <Drawer
                    title=""
                    placement="right"
                    closable={false}
                    onClose={this.onClose}
                    visible={this.state.visible}
                >
                    <MyMenu isProject={this.props.isProject} mode="vertical"/>
                </Drawer>
            </nav>
        );
    }
}

Navbar.propTypes = {
    isProject: PropTypes.bool
};


export default Navbar;