import React, {Component} from 'react';

import {Menu} from 'antd';
import {withPrefix} from "gatsby-link";
import {Link} from "gatsby";
import PropTypes from "prop-types";


class MyMenu extends Component {
    trimRight(str, charlist) {
        return str.replace(new RegExp("[" + charlist + "]+$"), "");
    };

    render() {
        let path = typeof window !== 'undefined' ? window.location.pathname : '';
        let selectedKeys = [];

        if(path) {
            path = this.trimRight(path, "/");
            if(path === "") {
                path = "/";
            }
            selectedKeys.push(path);
        }

        if(this.props.isProject) {
            selectedKeys.push(withPrefix("/"));
        }

        return (
            <Menu selectedKeys={selectedKeys}
                  mode={this.props.mode}
                  style={{lineHeight: "20px", borderBottom: "none", borderRight: "none", fontFamily: "Tahoma, sans-serif"}}>
                <Menu.Item key={withPrefix('/about')} style={{borderBottom: "1px solid transparent"}}>
                    <Link to='/about'>О НАС</Link>
                </Menu.Item>
                <Menu.Item key={withPrefix('/')} style={{borderBottom: "1px solid transparent"}}>
                    <Link to='/'>ПРОЕКТЫ</Link>
                </Menu.Item>
                <Menu.Item key={withPrefix('/press')} style={{borderBottom: "1px solid transparent"}}>
                    <Link to='/press'>ПУБЛИКАЦИИ</Link>
                </Menu.Item>
                <Menu.Item key={withPrefix('/contacts')} style={{borderBottom: "1px solid transparent"}}>
                    <Link to='/contacts'>КОНТАКТЫ</Link>
                </Menu.Item>
            </Menu>
        );
    }
}

MyMenu.propTypes = {
    isProject: PropTypes.bool,
    mode: PropTypes.string.isRequired
};


export default MyMenu;
