/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import "./layout.css"

import React from "react"
import PropTypes from "prop-types"
import {Link} from "gatsby"

import {Layout as L} from 'antd'
import {Col, Row} from "antd";
import Navbar from "./navbar";
import {Helmet} from "react-helmet";

import nerpa_arrow from "../images/nerpa_arrow.svg";
import nerpa_logo from "../images/nerpa_logo.svg";
import loading_gif from "../images/nerpa_loading.gif"

const Layout = ({isProject, isAbout, isNerpa, children}) => {
    return (
        <>
            <Helmet>
                <link rel="preload" href={loading_gif} as="image"/>
            </Helmet>
            <L style={{
                margin: `0 auto`,
                maxWidth: (1140 + 160),
                background: 'white',
            }}>
                <L.Header style={{marginTop: `76px`, background: "white", paddingLeft: "0", paddingRight: "25px"}}>
                    <Navbar isProject={isProject}/>
                </L.Header>

                <L.Content style={{marginTop: 55, minHeight: "calc(100vh - 360px)"}}
                           className="content">{children}</L.Content>

                <L.Footer style={{background: 'white', paddingLeft: "0"}}>
                    <Row gutter={[0, 20]}>
                        <Col flex="130px">
                            {isNerpa ?
                                <div></div> :
                                <Link to="/nerpa"><img className="arrow_bottom"
                                                       src={nerpa_arrow}
                                                       alt="NERPA Logo"/></Link>
                            }
                        </Col>
                    </Row>
                    <Row>
                        <Col flex="130px">
                            <Link to="/nerpa"><img className="logo_bottom"
                                                   src={nerpa_logo}
                                                   alt="NERPA Logo"/></Link>
                        </Col>
                        <Col flex="auto" className="text_bottom">
                            {!isAbout ? <>
                                <div>Санкт-Петербург, ул. Марата 20</div>
                                <div><a href="mailto:mail@nerpadesign.com">mail@nerpadesign.com</a></div>
                                <div><a href="tel:+7 921 341 43 08">+7 (921) 341 43 08</a></div>
                                <br/>
                            </> : <><br/></>}
                            <div>АРХИТЕКТУРНОЕ БЮРО NERPA</div>
                        </Col>
                    </Row>
                </L.Footer>
            </L>
        </>
    )
};

Layout.propTypes = {
    children: PropTypes.node.isRequired,
    isProject: PropTypes.bool
};

export default Layout
